 @import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@200;400;500;600&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

:root {
    font-size: 60%;
    --color-background: #000000;
    --color-background-dark: #1c1c1c;
    --color-background-light: #ffffff;
    --color-text-base: #FFFFFF;
    --color-text-base-dark: #1c1c1c;
    --color-text-base-shadow: #828282;
    --color-text-secondary: #ffbd59;
    --color-text-secondary-shadow: #a16914;
    --color-text-block: #4b4b4b;
    --color-button: #4b4b4b;
    --color-button-text: #ffbd59;
    --color-button-text-hover: #dc9730;
}

* {
    margin: 0;
    padding: 0;    
    box-sizing: border-box;
}

html, body, #root {
    height: 100vh;
}

body {
    background: var(--color-background);   
}

#root {
    display: flex;
    align-items: center;
    justify-content: center;
}

body,
input,
button,
textarea {
    font: 500 1.6rem 'League Spartan';
    color: var(--color-text-base);
}

.container {
    width: 90vw;    
}

@media (min-width: 700px) {
    :root {
        font-size: 62.5%;
    }
}