.page-error {
  height: 100%;  
  background: var(--color-secundary);
  background-image: url('../../assets/images/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.page-error .error-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.page-error .error-content h1 {
  margin-bottom: 10rem;
  font: 600 5rem 'League Spartan';
  color: var(--color-text-base-dark);
}

.page-error .error-content h3 {
  font: 400 4rem 'League Spartan';
  color: var(--color-text-base-dark);
}
